import * as React from 'react';
import styled from 'styled-components';
import {
  ButtonGroup,
  audiDarkTheme,
  audiLightTheme,
  Text,
  AudiPlatformProvider,
  Button,
  Layout,
} from '@audi/audi-ui-react-v2';
import type { Theme } from '@audi/audi-ui-react-v2';
import {
  COLOR_BASE_BRAND_BLACK,
  COLOR_BASE_BRAND_WHITE,
  SPACING_M_XS,
  SPACING_XL_XS,
  SPACING_XXL_L,
  SPACING_XXXL_L,
  PAGE_MARGIN_XL,
  BREAKPOINT_XS,
  BREAKPOINT_S,
  BREAKPOINT_M,
  BREAKPOINT_L,
  BREAKPOINT_XL,
  BREAKPOINT_XXL,
} from '@audi/audi-ui-design-tokens';
import { renderTextWithFootnotesReferencesV2, useContent } from '@oneaudi/feature-app-utils';

import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import { LayerContentHTML } from '@oneaudi/fa-one-layer/dist/utils';
import { useTracking } from '../tracking';
import type {
  BasicTeaserContent,
  BasicTeaserCtaButton,
  BasicTeaserServices,
  FeatureAppMeta,
} from '../../types';
import { LegalInfo } from './LegalInfo';
import { isDarkTheme, isDebugMode } from '../utils';
import { APP_ID } from '../../environment';
import mapContent from '../contentMapping/contentMapping';
import { LayerManagerContext } from '../context';
import RichText from './RichText';
import { setHeadlessUrl } from '../utils/setHeadlessUrl';

interface StyledContainerProps {
  mediaPosition?: BasicTeaserContent['image']['mediaPosition'];
  legalInfoDisplay?: boolean;
}

const StyledContainer = styled.div<StyledContainerProps>`
  margin: auto;
  background-color: ${({ theme }: { theme: Theme }) =>
    isDarkTheme(theme) ? COLOR_BASE_BRAND_BLACK : COLOR_BASE_BRAND_WHITE};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'media'
    'textarea';

  padding-block-end: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.xxl})`};

  @media screen and (min-width: ${BREAKPOINT_XS}px) {
    margin-inline-start: ${SPACING_M_XS}px;
    margin-inline-end: ${SPACING_M_XS}px;
  }
  @media screen and (min-width: ${BREAKPOINT_S}px) {
    margin-inline-start: ${SPACING_XL_XS}px;
    margin-inline-end: ${SPACING_XL_XS}px;
  }
  @media screen and (min-width: ${BREAKPOINT_M}px) {
    margin-inline-start: ${SPACING_XXL_L}px;
    margin-inline-end: ${SPACING_XXL_L}px;
  }

  @media screen and (min-width: ${BREAKPOINT_L}px) {
    ${({ mediaPosition }: StyledContainerProps) =>
      mediaPosition === 'right'
        ? `
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "textarea media";
    `
        : `
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "media textarea";
    `};

    margin-inline-start: ${SPACING_XXXL_L}px;
    margin-inline-end: ${SPACING_XXXL_L}px;
    padding-block-end: ${({ theme, legalInfoDisplay }: StyledContainerProps & { theme: Theme }) =>
      !legalInfoDisplay ? `var(${theme.responsive?.spacing.xxl})` : ''};
  }

  @media screen and (min-width: ${BREAKPOINT_XL}px) {
    ${({ mediaPosition }: StyledContainerProps) =>
      mediaPosition === 'right'
        ? `
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "textarea media";
    `
        : `
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "media textarea";
    `};
    margin-inline-start: ${PAGE_MARGIN_XL}px;
    margin-inline-end: ${PAGE_MARGIN_XL}px;
    padding-block-end: ${({ theme, legalInfoDisplay }: StyledContainerProps & { theme: Theme }) =>
      !legalInfoDisplay ? `var(${theme.responsive?.spacing.xxl})` : ''};
  }
`;

interface StyledMediaProps {
  aspectRatio?: BasicTeaserContent['image']['aspectRatio'];
  mediaPosition?: BasicTeaserContent['image']['mediaPosition'];
}

const StyledMedia = styled.div<StyledMediaProps>`
  grid-area: media;
  position: relative;
  height: 0;
  overflow: hidden;

  padding-top: ${({ aspectRatio }) => (aspectRatio === '1:1' ? '100' : '56.25')}%;

  div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
  }

  img {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.screen-size-xs {
      @media screen and (min-width: ${BREAKPOINT_XS}px) and (max-width: ${BREAKPOINT_S - 1}px) {
        display: block;
      }
    }

    &.screen-size-s {
      @media screen and (min-width: ${BREAKPOINT_S}px) and (max-width: ${BREAKPOINT_M - 1}px) {
        display: block;
      }
    }

    &.screen-size-m {
      @media screen and (min-width: ${BREAKPOINT_M}px) and (max-width: ${BREAKPOINT_L - 1}px) {
        display: block;
      }
    }

    &.screen-size-l {
      @media screen and (min-width: ${BREAKPOINT_L}px) and (max-width: ${BREAKPOINT_XL - 1}px) {
        display: block;
      }
    }

    &.screen-size-xl {
      @media screen and (min-width: ${BREAKPOINT_XL}px) and (max-width: ${BREAKPOINT_XXL - 1}px) {
        display: block;
      }
    }

    &.screen-size-xxl {
      @media screen and (min-width: ${BREAKPOINT_XXL}px) {
        display: block;
      }
    }
  }

  .image__container {
    @media screen and (min-width: ${BREAKPOINT_XS}px) {
      ${({ theme }: StyledMediaProps & { theme: Theme }) =>
        isDarkTheme(theme)
          ? `
          padding-inline-start: var(${theme.responsive?.spacing.l});
          padding-inline-end: var(${theme.responsive?.spacing.l});
          padding-block-start: var(${theme.responsive?.spacing.l});
            `
          : `

            `};
    }
    @media screen and (min-width: ${BREAKPOINT_L}px) {
      ${({ mediaPosition, theme }: StyledMediaProps & { theme: Theme }) =>
        isDarkTheme(theme)
          ? `
              padding-block-start: var(${theme.responsive?.spacing.xxl});
              ${
                mediaPosition === 'right'
                  ? `padding-inline-end: var(${theme.responsive?.spacing.xxl});`
                  : `padding-inline-start: var(${theme.responsive?.spacing.xxl});`
              }
            `
          : `

            `};
    }
  }
`;

const Image = styled.img`
  width: 100%;
`;

const StyledTextArea = styled.div`
  sup {
    position: initial;
    line-height: initial;
  }

  grid-area: textarea;
  align-self: center;

  @media screen and (min-width: ${BREAKPOINT_XS}px) {
    padding-block-start: ${({ theme }: { theme: Theme }) =>
      `var(${theme.responsive?.spacing.xxl})`};
    padding-inline-start: ${({ theme }: { theme: Theme }) =>
      isDarkTheme(theme) ? `var(${theme.responsive?.spacing.l})` : ''};
    padding-inline-end: ${({ theme }: { theme: Theme }) =>
      isDarkTheme(theme) ? `var(${theme.responsive?.spacing.l})` : ''};
  }

  @media screen and (min-width: ${BREAKPOINT_L}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-inline-start: ${({ theme }: { theme: Theme }) =>
      `var(${theme.responsive?.spacing.xxl})`};
    padding-inline-end: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.xxl})`};
    padding-block-start: ${({ theme }: { theme: Theme }) =>
      `var(${theme.responsive?.spacing.xxl})`};
  }

  .subheading {
    margin-block-start: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.l})`};
  }
  .text {
    margin-block-start: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.m})`};
  }
  .buttons {
    margin-block-start: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.l})`};
  }
  > div:first-child {
    margin-block-start: 0;
  }
`;

export const BasicTeaser = ({
  themeColor,
  image,
  heading,
  headingTag,
  subHeading,
  text,
  buttons,
  consumptionAndEmissions,
  disclaimers,
  supplier,
  vueFormatterService,
  localeService,
  meta,
}: BasicTeaserContent &
  BasicTeaserServices & { meta: FeatureAppMeta }): React.ReactElement | null => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [hasConsumptionAndEmissions, setHasConsumptionAndEmissions] = React.useState(false);
  const hasLegalInfo = !!consumptionAndEmissions?.length || !!disclaimers?.length;
  const { aspectRatio = '1:1', mediaPosition = 'left' } = image;
  const subHeadingTag =
    // SEO optimization: subHeading tag is one hierarchy level lower than heading
    (headingTag ? `h${parseInt(headingTag.replace(/\D/g, '') || '2', 10) + 1}` : 'h3') as
      | 'h3'
      | 'h4'
      | 'h5';
  const legalInfo = hasLegalInfo ? (
    <LegalInfo
      mediaPosition={mediaPosition}
      consumptionAndEmissions={consumptionAndEmissions}
      disclaimers={disclaimers}
      onCaeReady={() => {
        setHasConsumptionAndEmissions(true);
      }}
      vueFormatterService={vueFormatterService}
      localeService={localeService}
    />
  ) : null;
  const { registerImpressionTracking, sendReadyEvent, sendClickEvent } = useTracking(meta.id, {
    // implementer is Modus Create
    implementer: 37,
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    ceDataAvailable: `${hasConsumptionAndEmissions}`,
    imageSize: aspectRatio,
  });
  const layerManager = React.useContext(LayerManagerContext);

  React.useEffect(() => sendReadyEvent(), []);

  React.useEffect(() => {
    if (ref.current)
      registerImpressionTracking(ref.current, {
        value: heading || '',
      });
  }, [ref]);

  const handleOpenInLayer = React.useCallback((url: string) => {
    // eslint-disable-next-line react/destructuring-assignment
    layerManager?.openFocusLayer(
      () => <LayerContentHTML enableDeepLinking url={`${setHeadlessUrl(url)}`} />,
      {},
      {
        userCloseable: true,
        size: FocusLayerSizeV2.A,
      }
    );
  }, []);

  const shouldRenderButtons = () => {
    const isValidButton = (button: BasicTeaserCtaButton) =>
      button?.label && (button?.url || button?.openInTabOrLayer === 'chatbot');

    return buttons && buttons.some(isValidButton);
  };

  return (
    <AudiPlatformProvider theme={themeColor === 'dark' ? audiDarkTheme : audiLightTheme}>
      <StyledContainer
        ref={ref}
        mediaPosition={mediaPosition}
        data-testid="basic-teaser"
        legalInfoDisplay={hasLegalInfo}
      >
        <StyledMedia aspectRatio={aspectRatio} mediaPosition={mediaPosition}>
          <div className="image__container">
            <Image
              className="screen-size-xs screen-size-s screen-size-m screen-size-l screen-size-xl screen-size-xxl"
              src={image.src}
              alt={image.alt}
            />
          </div>
        </StyledMedia>

        <StyledTextArea>
          {heading && (
            <div>
              <Text variant="order2" as={headingTag || 'h2'}>
                {renderTextWithFootnotesReferencesV2(heading)}
              </Text>
            </div>
          )}
          {subHeading && (
            <div className="subheading">
              <Text variant="order3" as={subHeadingTag}>
                {renderTextWithFootnotesReferencesV2(subHeading)}
              </Text>
            </div>
          )}
          {text && (
            <div className="text">
              <RichText text={text} />
            </div>
          )}
          {shouldRenderButtons() && (
            <div className="buttons">
              <ButtonGroup spaceStackStart="l" spaceStackEnd="xl" variant="block-buttons">
                {buttons?.map(
                  ({ variant = 'primary', label, url, ariaLabel, openInTabOrLayer }, i) => {
                    if (label && openInTabOrLayer === 'chatbot') {
                      return (
                        <Button
                          // link that has the nm-j-poa classname attached to it will open the chat bot upon clicking
                          className="nm-j-poa"
                          variant={variant}
                          key={label}
                          aria-label={ariaLabel}
                          onClick={() => {
                            // eslint-disable-next-line no-console
                            console.log('open chatbot');
                          }}
                        >
                          {label}
                        </Button>
                      );
                    }
                    if (label && url) {
                      return (
                        <Button
                          variant={variant}
                          key={label}
                          aria-label={ariaLabel}
                          newWindow={openInTabOrLayer === 'tab'}
                          href={openInTabOrLayer === 'layer' ? undefined : url}
                          onClick={() => {
                            sendClickEvent({
                              elementName: 'button',
                              value: heading,
                              pos: `${i + 1}`,
                              targetURL: url,
                              label,
                            });

                            if (openInTabOrLayer === 'layer') {
                              handleOpenInLayer(url);
                            }
                          }}
                        >
                          {label}
                        </Button>
                      );
                    }
                    return <></>;
                  }
                )}
              </ButtonGroup>
            </div>
          )}
          {hasLegalInfo && legalInfo}
          {supplier?.supplierName && supplier?.supplierLink.label && supplier?.supplierLink.url && (
            <Layout spaceStackStart="m">
              <Text as="span" variant="copy2" spaceInlineEnd="m">
                {supplier.supplierName}
              </Text>
              <Text as="p" variant="copy2">
                <a
                  href={supplier.supplierLink.url}
                  aria-label={supplier.supplierLink.ariaLabel}
                  target="_blank"
                  rel="noreferrer"
                >
                  {supplier.supplierLink.label}
                </a>
              </Text>
            </Layout>
          )}
        </StyledTextArea>
      </StyledContainer>
    </AudiPlatformProvider>
  );
};

export const BasicTeaserContentLoader = ({
  initialContent,
  localeService,
  vueFormatterService,
  meta,
}: {
  meta: FeatureAppMeta;
  initialContent?: BasicTeaserContent;
} & BasicTeaserServices): React.ReactElement | null => {
  // eslint-disable-next-line
  const rawContent: any = useContent<BasicTeaserContent>() || initialContent;
  const content = mapContent(rawContent);

  if (isDebugMode())
    // eslint-disable-next-line no-console
    console.debug(`${APP_ID} ->`, {
      content,
    });

  if (!content) return null;

  return (
    <BasicTeaser
      {...content}
      localeService={localeService}
      vueFormatterService={vueFormatterService}
      meta={meta}
    />
  );
};
