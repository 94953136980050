/* eslint-disable @typescript-eslint/no-shadow */

import * as React from 'react';
import {
  makeEmptyEventAttributes,
  makeEmptyComponentAttributes,
  createAndBootstrapTrackingService,
} from '@oneaudi/oneaudi-os-onesight';
import type {
  DataLayerEvent,
  DataLayerEventAttributes,
  TrackingServiceV3,
  DataLayerEventInfo,
} from '@oneaudi/oneaudi-os-onesight';
import type { XOR } from 'ts-xor';

import { APP_VERSION } from '../environment';
import { APP_NAME } from './utils';

const componentNameWithSpaces = APP_NAME;
const componentNameWithDashes = componentNameWithSpaces.replace(/ /g, '-');

type EventAction =
  | 'page_load'
  | 'step'
  | 'opt-in'
  | 'view_change'
  | 'internal_link'
  | 'impression'
  | 'feature_app_ready'
  | 'exit_link'
  | 'download'
  | 'image'
  | 'video'
  | 'configuration'
  | 'conflict'
  | 'favorite'
  | 'call'
  | 'anchor'
  | 'email'
  | 'content'
  | 'navigation'
  | 'tooltip'
  | 'error'
  | 'filter'
  | 'form_field'
  | 'non_interaction'
  | 'print'
  | 'user_account_change'
  | 'rating'
  | 'compare'
  | 'order';

export type TrackingClickEvent = Partial<DataLayerEventAttributes> & {
  name?: string;
} & XOR<
    {
      action?: EventAction;
      targetURL: string;
    },
    {
      action: EventAction;
      targetURL?: string;
    }
  >;

export type TrackClickEvent = (event: TrackingClickEvent) => void;

const createEvent = ({
  attributes,
  action,
  name,
}: {
  attributes?: Partial<DataLayerEventAttributes>;
  action: EventAction;
  name: DataLayerEventInfo['eventName'];
}): DataLayerEvent => ({
  attributes: Object.freeze({
    ...makeEmptyEventAttributes(),
    componentName: componentNameWithDashes,
    currentURL: typeof window !== 'undefined' ? window.location.href : '',
    ...attributes,
  } as DataLayerEventAttributes),
  eventInfo: {
    eventAction: action,
    eventName: name,
  },
});

const trackClickEvent = (
  tracking: TrackingServiceV3,
  { name, action: _action, ...attributes }: TrackingClickEvent
): void => {
  const action =
    _action || (attributes.targetURL ? tracking.evaluateLinkType(attributes.targetURL) : '');
  if (action)
    tracking.track(
      createEvent({
        action,
        name: `${componentNameWithSpaces} - ${
          name ? `${name}` : `click on ${attributes.elementName || 'UNDEFINED'}`
        }`,
        attributes,
      })
    );
};

export const useTracking = (
  featureAppId: string,
  attributes?: {
    implementer: number;
    ceDataAvailable?: string;
    [key: string]: unknown;
  }
): {
  service: TrackingServiceV3 | undefined;
  registerImpressionTracking: (el: Element, attributes?: Partial<DataLayerEventAttributes>) => void;
  sendReadyEvent: (attributes?: Partial<DataLayerEventAttributes>) => void;
  sendClickEvent: (event: TrackingClickEvent) => void;
} => {
  if (typeof window === 'undefined') {
    // skip tracking initialization when no window object is present (=> SSR)
    return {
      service: undefined,
      registerImpressionTracking: () => null,
      sendReadyEvent: () => null,
      sendClickEvent: () => null,
    };
  }

  const service = React.useRef<TrackingServiceV3>(
    createAndBootstrapTrackingService({
      appId: featureAppId,
      appName: APP_NAME,
      appVersion: APP_VERSION,
    })
  );

  React.useEffect(() => {
    if (attributes)
      service.current.updateComponent({
        attributes: {
          ...makeEmptyComponentAttributes(),
          version: APP_VERSION,
          ...attributes,
        },
        componentInfo: {
          componentName: componentNameWithDashes,
          componentID: featureAppId,
        },
      });
  }, [service, attributes]);

  const sendReadyEvent = React.useCallback(
    (attributes?: Partial<DataLayerEventAttributes>) => {
      if (service && service.current) {
        return service.current.track(
          createEvent({
            action: 'feature_app_ready',
            name: `${componentNameWithSpaces} - feature app ready`,
            attributes,
          })
        );
      }
      return null;
    },
    [service]
  );

  const registerImpressionTracking = React.useCallback(
    (
      el: Parameters<typeof service.current.registerImpressionTracking>[0],
      attributes?: Partial<DataLayerEventAttributes>
    ) => {
      if (service && service.current) {
        return service.current.registerImpressionTracking(
          el,
          (): DataLayerEvent => ({
            eventInfo: {
              eventAction: 'impression',
              eventName: `${componentNameWithSpaces} - impression`,
            },
            attributes: {
              componentName: componentNameWithDashes,
              ...attributes,
            } as DataLayerEventAttributes,
          })
        );
      }
      return null;
    },
    [service]
  );

  const sendClickEvent = React.useCallback((event: TrackingClickEvent) => {
    if (service && service.current) {
      return trackClickEvent(service.current, event);
    }
    return null;
  }, []);

  return {
    service: service.current,
    registerImpressionTracking,
    sendReadyEvent,
    sendClickEvent,
  };
};
