import * as React from 'react';
import type {
  FeatureAppDefinition,
  FeatureAppEnvironment,
  FeatureServices,
} from '@feature-hub/core';
import type { ReactFeatureApp } from '@feature-hub/react';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import type { TrackingServiceV3 } from '@oneaudi/oneaudi-os-onesight';
import { ContentServiceV1 } from '@oneaudi/content-service';
import type { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { ContentContextProvider, FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import type { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import { BasicTeaserContentLoader } from './components/BasicTeaser';
import type { BasicTeaserContent } from '../types';
import { LayerManagerContext } from './context';

interface FeatureServiceDependencies extends FeatureServices {
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'audi-tracking-service': TrackingServiceV3;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'layer-manager': LayerManagerV27;
}

// eslint-disable-next-line import/no-default-export
export default {
  dependencies: {
    externals: {
      react: '^16.13.1 || ^17.0.2',
      'react-dom': '^16.13.1 || ^17.0.2',
      'styled-components': '5.1.1',
      '@audi/audi-ui-react-v2': '^2.1.0',
      '@feature-hub/react': '^2.9.0',
    },
    featureServices: {
      'audi-content-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'locale-service': '^1.0.0',
      'layer-manager': '^2.7.2',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-tracking-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
    },
  },
  create: ({
    featureServices: {
      'audi-content-service': contentService,
      'locale-service': localeService,
      'dbad:audi-vue-formatter-service': vueFormatterService,
      'audi-footnote-reference-service': footnoteReferenceServiceScopeManager,
      'layer-manager': layerManager,
    },
    config,
    featureAppId: id,
  }: FeatureAppEnvironment<FeatureServiceDependencies, BasicTeaserContent>) => {
    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();

    return {
      render: () => (
        <ContentContextProvider contentService={contentService}>
          <LayerManagerContext.Provider value={layerManager}>
            <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
              <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                <BasicTeaserContentLoader
                  vueFormatterService={vueFormatterService}
                  localeService={localeService}
                  initialContent={config}
                  meta={{ id }}
                />
              </div>
            </FootnoteContextProvider>
          </LayerManagerContext.Provider>
        </ContentContextProvider>
      ),
    };
  },
} as FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies>;
