import { Text as TextWrapper } from '@audi/audi-ui-react-v2';
import React, { memo } from 'react';
import styled from 'styled-components';
import { convertRichText } from '../utils/textUtils';

const StyledText = styled.span`
  & p {
    margin: 0;
    padding: 0;

    & + p {
      margin-block-start: var(${(props) => props.theme.responsive.spacing.m});
    }
  }
`;

export interface TextProps {
  readonly text: string;
}

export default memo(function RichText({ text }: TextProps) {
  return (
    <TextWrapper as="span">
      <StyledText>{convertRichText(text)}</StyledText>
    </TextWrapper>
  );
});
