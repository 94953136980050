import { ContentFragment } from '@oneaudi/falcon-tools';
/* eslint-disable camelcase */
import { BasicTeaserContent, HeadingTag, ThemeColor } from '../../types';

interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    imageScr: string;
    imageAlt: string;
    imageAspectRatio: '16:9' | '1:1';
    imageMediaPosition: 'left' | 'right';
    heading: string;
    headingTag: 'h2' | 'h3' | 'h4';
    subHeading: string;
    text: string;
    firstButtonLabel: string;
    firstButtonUrl: string;
    firstButtonVariant: 'primary' | 'secondary';
    firstButtonAriaLabel: string;
    firstButtonOpenInTabOrLayer: 'page' | 'tab' | 'layer' | 'chatbot';
    secondButtonLabel: string;
    secondButtonUrl: string;
    secondButtonVariant: 'primary' | 'secondary';
    secondButtonAriaLabel: string;
    secondButtonOpenInTabOrLayer: 'page' | 'tab' | 'layer' | 'chatbot';
    themeColor: 'light' | 'dark';
    consumptionAndEmissions: string[];
    disclaimers: string[];
    supplierName: string;
    supplierLinkLabel: string;
    supplierLinkUrl: string;
    supplierLinkAriaLabel: string;
  };
}

interface EditorJsonContent extends BasicTeaserContent {
  __type?: 'editor.json';
  image: {
    src: string;
    alt: string;
    aspectRatio: '16:9' | '1:1';
    mediaPosition: 'left' | 'right';
  };
  heading: string;
  headingTag: HeadingTag;
  subHeading: string;
  text: string;
  buttons: {
    label: string;
    url: string;
    variant: 'primary' | 'secondary';
    ariaLabel: string;
    openInTabOrLayer: 'page' | 'tab' | 'layer' | 'chatbot';
  }[];
  themeColor: ThemeColor;
  consumptionAndEmissions: string[];
  disclaimers: string[];
  supplier?: {
    supplierName: string;
    supplierLink: {
      label: string;
      url: string;
      ariaLabel: string;
    };
  };
}

const mapContent = (content: EditorJsonContent | FalconContent): EditorJsonContent => {
  if (!content || Object.keys(content).length === 0) {
    // eslint-disable-next-line
    console.warn('No Editor content found for Basic Teaser');

    return {} as EditorJsonContent;
  }

  if (content.__type !== 'aem-headless') {
    return content as EditorJsonContent;
  }
  return convertHeadlessContentToEditorFormat(content);
};

const convertHeadlessContentToEditorFormat = (content: FalconContent): EditorJsonContent => {
  const { fields } = content;
  const {
    imageScr,
    imageAlt,
    imageAspectRatio,
    imageMediaPosition,
    heading,
    headingTag,
    subHeading,
    text,
    firstButtonLabel,
    firstButtonUrl,
    firstButtonVariant,
    firstButtonAriaLabel,
    firstButtonOpenInTabOrLayer,
    secondButtonLabel,
    secondButtonUrl,
    secondButtonVariant,
    secondButtonAriaLabel,
    secondButtonOpenInTabOrLayer,
    themeColor,
    consumptionAndEmissions,
    disclaimers,
    supplierName,
    supplierLinkLabel,
    supplierLinkUrl,
    supplierLinkAriaLabel,
  } = fields;

  const normalizedContent: EditorJsonContent = {
    image: {
      src: imageScr,
      alt: imageAlt,
      aspectRatio: imageAspectRatio,
      mediaPosition: imageMediaPosition,
    },
    heading,
    headingTag,
    subHeading,
    text,
    buttons: [
      {
        label: firstButtonLabel,
        url: firstButtonUrl,
        variant: firstButtonVariant,
        ariaLabel: firstButtonAriaLabel,
        openInTabOrLayer: firstButtonOpenInTabOrLayer,
      },
      {
        label: secondButtonLabel,
        url: secondButtonUrl,
        variant: secondButtonVariant,
        ariaLabel: secondButtonAriaLabel,
        openInTabOrLayer: secondButtonOpenInTabOrLayer,
      },
    ],
    themeColor,
    consumptionAndEmissions,
    disclaimers,
    supplier: {
      supplierName,
      supplierLink: {
        label: supplierLinkLabel,
        url: supplierLinkUrl,
        ariaLabel: supplierLinkAriaLabel,
      },
    },
  };

  const contentInEditorJsonFormat: EditorJsonContent = {
    ...normalizedContent,
  };

  return contentInEditorJsonFormat;
};

export default mapContent;
