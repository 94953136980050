import * as React from 'react';
import {
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
  getConsumptionsAndEmissions,
} from '@oneaudi/feature-app-utils';
import { Text } from '@audi/audi-ui-react-v2';

import type { BasicTeaserServices } from '../../types';

interface ConsumptionAndEmissionsProps extends BasicTeaserServices {
  identifiers: string[];
  onCaeReady?: () => void;
}

interface CaeData {
  consumption: React.ReactNodeArray | undefined;
  emissions: React.ReactNodeArray | undefined;
}

export const ConsumptionAndEmissions = ({
  identifiers,
  vueFormatterService,
  localeService,
  onCaeReady,
}: ConsumptionAndEmissionsProps): React.ReactElement | null => {
  const [caeData, setCaeData] = React.useState<CaeData[]>();

  React.useEffect(() => {
    if (vueFormatterService && localeService)
      (async () => {
        const consumptionsAndEmissionsValues = await getConsumptionsAndEmissions(
          identifiers,
          vueFormatterService,
          localeService
        );

        const _caeData: CaeData[] = consumptionsAndEmissionsValues
          .filter((cae) => {
            return cae?.formattedConsumption && cae?.formattedEmission;
          })
          .map((cae) => {
            return {
              consumption: renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(cae.formattedConsumption || '')
              ),
              emissions: renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(cae.formattedEmission || '')
              ),
            };
          });
        if (_caeData.length) setCaeData(_caeData);
      })();
  }, []);

  React.useEffect(() => {
    if (caeData?.length) onCaeReady?.();
  }, [caeData?.length]);

  if (!caeData?.length) return null;

  return (
    <>
      {caeData.map((x, i) => (
        <>
          <Text key={['c', i, x.consumption].join('_')} variant="copy2">
            {x.consumption}
          </Text>
          <Text key={['e', i, x.emissions].join('_')} variant="copy2" spaceStackEnd="xs">
            {x.emissions}
          </Text>
        </>
      ))}
    </>
  );
};
