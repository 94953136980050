import * as React from 'react';
import styled from 'styled-components';
import { Text, Theme } from '@audi/audi-ui-react-v2';
import { COLOR_BASE_BRAND_BLACK, COLOR_BASE_BRAND_WHITE } from '@audi/audi-ui-design-tokens';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';

import { ConsumptionAndEmissions } from './ConsumptionAndEmissions';
import { isDarkTheme } from '../utils';
import type { BasicTeaserContent, BasicTeaserServices } from '../../types';

interface LegalInfoProps
  extends Pick<BasicTeaserContent, 'consumptionAndEmissions' | 'disclaimers'>,
    BasicTeaserServices {
  mediaPosition?: BasicTeaserContent['image']['mediaPosition'];
  onCaeReady?: () => void;
}

const Container = styled.div`
  background-color: ${({ theme }: { theme: Theme }) =>
    isDarkTheme(theme) ? COLOR_BASE_BRAND_BLACK : COLOR_BASE_BRAND_WHITE};
`;

const LegalInfoContainer = styled.div<{
  mediaPosition?: BasicTeaserContent['image']['mediaPosition'];
}>`
  padding-block-start: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.m})`};
`;

export const LegalInfo = ({
  consumptionAndEmissions,
  vueFormatterService,
  localeService,
  onCaeReady,
  disclaimers,
  mediaPosition,
}: LegalInfoProps): React.ReactElement | null => {
  const hasContent =
    consumptionAndEmissions?.filter((x) => !!x).length || disclaimers?.filter((x) => !!x).length;

  if (!hasContent) return null;

  return (
    <Container>
      <LegalInfoContainer mediaPosition={mediaPosition}>
        {consumptionAndEmissions && (
          <ConsumptionAndEmissions
            identifiers={consumptionAndEmissions}
            localeService={localeService}
            vueFormatterService={vueFormatterService}
            onCaeReady={onCaeReady}
          />
        )}
        {disclaimers?.map((x, i) => (
          <Text key={['d', i, x].join('_')} variant="copy2" spaceStackEnd="xs">
            {renderTextWithFootnotesReferencesV2(x)}
          </Text>
        ))}
      </LegalInfoContainer>
    </Container>
  );
};
